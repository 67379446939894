import React from "react";

export default function OrangeLefrArrowSvg() {
  return (
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="https://www.w3.org/2000/svg "
      className="!min-w-[19px]"
    >
      <g clipPath="url(#clip0_2545_2573)">
        <path
          d="M1 6.55556H18M18 6.55556C16.0062 5.25926 11.7793 2.33333 10.8222 1M18 6.55556L10.8222 11"
          stroke="#FF8E5E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2545_2573">
          <rect width="19" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
